import { Box, Drawer, Stack, Toolbar } from "@mui/material";
import { AppToolbar } from "../../component/Toolbar";
import { useState } from "react";
import { Route, Routes } from "react-router-dom";
import { ROUTE_ENDPOINTS } from "../../../data/constant/RouteConstants";
import { DrawerView } from "./DrawerView";
import { HomeViewModel } from "./HomeViewModel";
import { Error404View } from "../../component/404View";
import useWindowDimensions from "../../../core/utils/UseWindowDimensions";
import { COLOR_MAIN, COLOR_SECONDARY } from "../../../data/colors/Colors";
import { NoBoxSelectedView } from "../box/NoBoxSelectedView";
import { BoxDetailsView } from "../box/details/BoxDetailsView";
import {
  provideGetAllBoxesUseCase,
  provideSearchBoxesUseCase,
} from "../../di/UseCasesModule";

export default function HomeView() {
  const [mobileOpen, setMobileOpen] = useState(false);
  const [isClosing, setIsClosing] = useState(false);
  const handleDrawerClose = () => {
    setIsClosing(true);
    setMobileOpen(false);
  };

  const handleDrawerTransitionEnd = () => {
    setIsClosing(false);
  };

  const handleDrawerToggle = () => {
    if (!isClosing) {
      setMobileOpen(!mobileOpen);
    }
  };

  const { width } = useWindowDimensions();

  const drawerWidth = 0.3 * width;
  const drawerWidthMobile = 0.7 * width;

  const viewModel = HomeViewModel(
    provideGetAllBoxesUseCase(),
    provideSearchBoxesUseCase()
  );

  return (
    <Box sx={{ display: "flex" }}>
      <AppToolbar handleDrawerToggle={handleDrawerToggle} />
      <Box sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}>
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onTransitionEnd={handleDrawerTransitionEnd}
          onClose={handleDrawerClose}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidthMobile,
            },
          }}
        >
          {viewModel.boxes && (
            <DrawerView
              boxes={viewModel.boxes}
              getBoxes={viewModel.getBoxes}
              showMoreButton={viewModel.showLoadMoreButton}
              isLoading={viewModel.isLoading}
              handleToggleDrawer={mobileOpen ? handleDrawerToggle : undefined}
              searchBoxes={viewModel.searchBoxes}
            />
          )}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              borderColor: COLOR_SECONDARY,
              borderWidth: 4,
            },
          }}
          open
        >
          {viewModel.boxes && (
            <DrawerView
              boxes={viewModel.boxes}
              showMoreButton={viewModel.showLoadMoreButton}
              getBoxes={viewModel.getBoxes}
              searchBoxes={viewModel.searchBoxes}
              isLoading={viewModel.isLoading}
              handleToggleDrawer={mobileOpen ? handleDrawerToggle : undefined}
            />
          )}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
        }}
      >
        <Toolbar />
        <Routes>
          <Route
            path={`${ROUTE_ENDPOINTS.BOXES}`}
            element={<NoBoxSelectedView />}
          />
          <Route
            path={`${ROUTE_ENDPOINTS.BOXES}/:id`}
            element={<BoxDetailsView />}
          />
          <Route path="*" element={<Error404View />} />
        </Routes>
      </Box>
    </Box>
  );
}
