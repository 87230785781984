import { AccessToken, Auth, Message } from "../../domain/model/Auth";
import { Box } from "../../domain/model/Box";
import { ApiService } from "../api/ApiService";
import { Response } from "../api/Response";
import { API_ENDPOINTS } from "../constant/ApiConstants";
import NetworkApi from "./NetworkApi";

export default class NetworkApiImpl implements NetworkApi {
  apiService: ApiService;

  constructor(apiService: ApiService) {
    this.apiService = apiService;
  }
  login(auth: Auth): Promise<Response<AccessToken>> {
    return this.apiService.post<AccessToken>(API_ENDPOINTS.LOGIN, auth);
  }

  // box
  getAllBoxes(skip?: number, limit?: number): Promise<Response<Array<Box>>> {
    var url = `${API_ENDPOINTS.BOXES}`;
    if (limit !== undefined) {
      url += `?limit=${limit}`;
      if (skip !== undefined) {
        url += `&skip=${skip}`;
      }
    }
    return this.apiService.get<Array<Box>>(url);
  }
  getBox(id: string): Promise<Response<Box>> {
    return this.apiService.get<Box>(`${API_ENDPOINTS.BOXES}/${id}`);
  }
  deleteBox(id: string): Promise<Response<Message>> {
    return this.apiService.delete<Message>(`${API_ENDPOINTS.BOXES}/${id}`);
  }
  updateBox(box: Box): Promise<Response<Message>> {
    return this.apiService.put<Message>(
      `${API_ENDPOINTS.BOXES}/${box._id}`,
      box
    );
  }
  searchBoxes(name: string): Promise<Response<Array<Box>>> {
    return this.apiService.get<Array<Box>>(
      `${API_ENDPOINTS.BOXES}${API_ENDPOINTS.SEARCH}?q=${name}`
    );
  }
}
